import React from "react";
import classNames from "classnames";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import FAQListItem from "./FAQListItem";

export default function FAQList({ faqs }) {
  return (
    <dl className="mt-6 space-y-6 divide-y divide-gray-200">
      {faqs.map(({ node: faq }, idx) => {
        return (
          <FAQListItem
            question={faq.frontmatter.question}
            answer={faq.frontmatter.answer}
          />
        );
      })}
    </dl>
  );
}
