import React from "react";
import WorkoutModal from "../../components/Modal/WorkoutModal";
import { useModal } from "../../../provider";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

const sessionQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      nodes {
        frontmatter {
          sessions {
            name
            description
            image {
              childImageSharp {
                gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;

export default function Workouts({ id }) {
  const modal = useModal();
  const session_data = useStaticQuery(sessionQuery);
  let sessions =
    session_data?.allMarkdownRemark?.nodes[0]?.frontmatter?.sessions;
  return (
    <div className="mx-4 sm:mx-16" id={id}>
      <div className="mt-12 lg:mt-24 mb-8 lg:mb-12">
        <h2 className="text-center text-heading uppercase text-3xl sm:text-5xl text-feat-blue">
          Our Workouts
        </h2>
        <h3 className="text-center pt-8 text-base text-feat-darkgray-300 max-w-4xl m-auto">
          FEAT Fitness are the outdoor experts. Training outdoors is different
          to the sterile and controlled indoor gym environment, and this is why
          so many people love it. With over 15 years of training people outdoors
          we truly know how to make the most of our varied environment. Our FEAT
          workouts are Fun, Varied & Connected, you'll gain so much from our
          sessions than just reaching your goals, we'll hit these too!
        </h3>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-rows-2 lg:grid-cols-3">
        {sessions.map((session) => (
          <div
            className="relative flex mx-8 mb-16 h-60 cursor-pointer"
            key={session.name}
            role="button"
            tabIndex={0}
            onClick={() => modal.openWorkoutModalOpen(session)}
            onKeyDown={(ev) => {
              if (ev.keyCode === 13) {
                modal.openWorkoutModalOpen(session);
              }
            }}
          >
            <GatsbyImage
              className="absolute-important inset-0 w-full h-full object-center object-cover"
              image={getImage(session.image)}
              alt={session.name}
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-400 to-transparent mix-blend-multiply" />
            <div className="relative w-full flex flex-col items-start justify-end bg-black bg-opacity-10 p-8 pb-4 sm:pt-12 sm:px-12 sm:pb-8">
              <p className="mt-1 text-2xl font-medium text-white uppercase text-heading">
                {session.name}
              </p>
              <div className="flex w-full items-center">
                <p className="text-white text-sm font-light whitespace-nowrap overflow-ellipsis overflow-x-hidden">
                  {session.description}
                </p>
                <span className="text-white font-light whitespace-nowrap text-right uppercase underline text-xs cursor-pointer">
                  Read More
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <WorkoutModal />
    </div>
  );
}
