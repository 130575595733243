import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import FAQList from "../../components/FAQList";

export default function FAQs({ id }) {
  const faq_data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "faq-page" } } }
        sort: { order: ASC, fields: [frontmatter___order] }
      ) {
        edges {
          node {
            id
            html
            fields {
              slug
            }
            frontmatter {
              title
              meta_title
              meta_description
              question
              answer
            }
          }
        }
      }
    }
  `);

  const faqs = faq_data.allMarkdownRemark.edges;
  return (
    <div id={id}>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="font-heading uppercase text-2xl md:text-3xl text-center text-feat-blue-600">
            Frequently Asked Questions
          </h2>
          <FAQList faqs={faqs} />
        </div>
      </div>
    </div>
  );
}
