import React from "react";
import HeroHeader from "../components/HeroHeader";
import Seo from "../components/Seo";
import Layout from "../components/layout";
import OurDifference from "../sections/OurDifference";
import FAQs from "../sections/FAQs";
import HowItWorks from "../sections/HowItWorks";
import Outcomes from "../sections/Outcomes";
import Workouts from "../sections/Workouts";

const ourWorkoutsId = "our-workouts";
const faqsId = "faqs";
const ourDifferenceId = "our-difference";
const outcomesId = "outcomes";

const OurTrainingPage = () => {
  return (
    <Layout>
      <Seo
        title="Our Training | FEAT Fitness"
        meta_title="Our Training | FEAT Fitness"
        meta_desc="FEAT Fitness specialises in outdoor group fitness training. We bring authentic human connection to peoples world's through fun community outdoor training."
        slug="our-training"
      />
      <main>
        <HeroHeader
          title="Our Training"
          options={[
            { title: "Our Workouts", to: `#${ourWorkoutsId}` },
            { title: "Our Difference", to: `#${ourDifferenceId}` },
            { title: "FAQs", to: `#${faqsId}` },
            { title: "Outcomes", to: `#${outcomesId}` },
            // { title: "Free Trial", highlighted: true },
          ]}
        />
        <HowItWorks />
        <Workouts id={ourWorkoutsId} />
        <OurDifference id={ourDifferenceId} />
        <FAQs id={faqsId} />
        <hr />
        <Outcomes id={outcomesId} showFrontOnly />
      </main>
    </Layout>
  );
};

export default OurTrainingPage;
