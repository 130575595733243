import React from "react";
import classNames from "classnames";
import { Transition } from "@headlessui/react";
import Heading from "../../components/Heading";

const workouts = [
  {
    title: "Variety",
    description:
      "Training with FEAT Fitness, you'll never do the same session twice. We are consistently modifying the equipment and training focus with our programming to ensure that you get a workout that is always challenging you in fun ways! Training with FEAT's style of variety, we load the body in different ways and challenge the mind in our training. This helps build overall strength and not just focussed on one muscle or movement.",
    image: {
      src: "/img/training/BarbellsRushcuttersBay.jpg",
      extraClassNames: "object-bottom",
    },
  },
  {
    title: "Connectedness",
    description:
      "Training together provides another level to your training. Being connected to those in your community not only naturally makes training even more fun, but it brings you closer to yourself and your training. Laughter & smiles, along with an amazing session, is what FEAT is all about, and that is what you get when you train with your fellow community members.",
    image: {
      src: "/img/training/SlamballsMaroubra.jpg",
      extraClassNames: "transform -scale-x-1",
    },
  },
  {
    title: "Fun",
    description:
      "At FEAT it's the simple differences that make our training so much fun. We create an environment for people to play in the outdoors and ultimately have more fun. We believe having fun through play is your gateway to full self-expression and a true fulfilling way of being. We promise there is never a dull moment when you come down and train with FEAT Fitness.",
    image: { src: "/img/training/JumpingFunBalmoral.jpg" },
  },
];

export default function OurDifference({ id }) {
  return (
    <div id={id}>
      <div className="mt-8 py-8 sm:py-16 sm:mb-2 bg-feat-lightgray">
        <h2 className="text-center text-heading uppercase text-3xl sm:text-5xl text-feat-blue">
          Our Difference
        </h2>
        <Heading level={4} className="text-center pt-8">
          Training with FEAT isn't the same group classes or bootcamps you may
          be used to. This is why.
        </Heading>
      </div>
      {workouts.map((workout, idx) => {
        const reverse = idx % 2 === 1;
        return (
          <div key={workout.title}>
            <Transition
              appear={true}
              show={true}
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <div className="relative min-h-half-screen sm:overflow-hidden bg-feat-lightgrey">
                <div
                  className={classNames(
                    { "flex-row-reverse sm:text-right": reverse },
                    "absolute z-30 flex w-full h-full text-center sm:text-left"
                  )}
                >
                  <div
                    className={classNames(
                      "relative z-30 sm:w-3/5 lg:w-2/5 px-10 py-8 text-feat-blue sm:py-10"
                    )}
                  >
                    <h2 className="text-heading uppercase text-2xl sm:text-4xl text-feat-blue-700 sm:text-feat-darkgray">
                      {workout.title}
                    </h2>
                    <div className="pt-4 text-base text-feat-darkgrey-700 sm:text-feat-darkgray">
                      {workout.description}
                    </div>
                  </div>
                  <div
                    className={classNames(
                      { "flex-row-reverse": reverse },
                      "absolute top-0 right-0 flex w-full h-full"
                    )}
                  >
                    <div className="hidden sm:block w-1/5 h-full bg-feat-lightgrey"></div>
                    <div className="hidden sm:block relative w-1/5 left-0">
                      <svg
                        fill="currentColor"
                        viewBox="0 0 100 100"
                        className={classNames(
                          { "right-0": reverse },
                          "absolute inset-y-0 z-20 h-full text-feat-lightgrey"
                        )}
                      >
                        <polygon
                          id="diagonal"
                          points={
                            reverse
                              ? "0,0 100,0 100,100 50,100"
                              : "0,0 100,0 50,100 0,100"
                          }
                        ></polygon>
                      </svg>
                      <svg
                        fill="currentColor"
                        viewBox="0 0 100 100"
                        className={classNames(
                          reverse ? "right-0 mr-6" : "ml-6",
                          "absolute inset-y-0 z-10 h-full text-feat-blue opacity-50"
                        )}
                      >
                        <polygon
                          points={
                            reverse
                              ? "0,0 100,0 100,100 50,100"
                              : "0,0 100,0 50,100 0,100"
                          }
                        ></polygon>
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    reverse ? "left-0" : "right-0",
                    "absolute top-0 block w-full sm:w-8/12 h-full"
                  )}
                >
                  <img
                    alt={workout.title}
                    className={classNames(
                      workout.image.extraClassNames,
                      "object-cover min-w-full h-full opacity-20 sm:opacity-100"
                    )}
                    src={workout.image.src}
                  />
                </div>
              </div>
            </Transition>
            <div className="hidden sm:block h-3 bg-white"></div>
          </div>
        );
      })}
    </div>
  );
}
