import React from "react";
import { gaEvent } from "../../helpers";
import { useModal } from "../../../provider";
import { StaticImage } from "gatsby-plugin-image";

export default function Workouts({ id }) {
    const modal = useModal();

    const features = [
        {
            name: "Free Week Trial",
            description:
                "We believe that you'll love FEAT so much, that before we ask for any monetary commitment, we let you come and experience an entire week with FEAT to show you why you'll love it so much.",
        },
        {
            name: "FEAT Accountability Model",
            description:
                "FEAT is unique because we are big believers in support through accountability. Through our personalised accountability model, you'll always be in communication with your trainer, this starts with your weekly Sunday text to book in your training days for the week.",
        },
        {
            name: "Training at FEAT",
            description:
                "All our training sessions meet in the park and we provide mostly everything you'll need for the session. You may want to bring your own water bottle or face towel. All our sessions are different and you can read more about our variety of our sessions below.",
        },
        {
            name: "Going Away?",
            description:
                "We don't have lock in contract periods or limited holidays in our memberships. If you have a holiday booked, or away for a period of time, you can simply freeze your membership to only pay for the weeks you're here training",
        },
    ];
    return (
        <div className="mx-4 my-16 sm:mx-16" id={id}>
            <div className="bg-white">
                <section aria-labelledby="how-it-works" className="relative">
                    <div className="aspect-w-3 aspect-h-2 overflow-hidden sm:aspect-w-5 lg:aspect-none lg:absolute lg:w-1/2 lg:h-full lg:pr-4 xl:pr-16">
                        <StaticImage
                            className="h-full w-full object-center object-cover lg:h-full lg:w-full"
                            src="../../../static/img/HowItWorksPhoto.jpg"
                            alt="FEAT Training"
                            loading="eager"
                        />
                    </div>

                    <div className="max-w-2xl mx-auto pt-4 pb-16 px-4 sm:pb-6 sm:px-6 lg:max-w-7xl lg:pt-4 lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-24">
                        <div className="lg:col-start-2">
                            <h2 className="text-center text-heading uppercase text-3xl sm:text-5xl text-feat-blue">
                                How It Works
                            </h2>
                            <h3 className="text-center pt-8 text-base text-feat-darkgray-300">
                                Read more about how we operate at FEAT and what
                                you can expect from your{" "}
                                <span
                                    className="text-feat-orange underline cursor-pointer"
                                    onClick={() => {
                                        gaEvent(
                                            "Button",
                                            "HIW",
                                            "free trial - how it works"
                                        );
                                        modal.openFreeTrialModal();
                                    }}
                                >
                                    free week trial
                                </span>
                                , all the way to becoming a regular member.
                            </h3>

                            <dl className="mt-10 grid grid-cols-1 gap-y-10 gap-x-8 text-base sm:grid-cols-2">
                                {features.map((feature) => (
                                    <div key={feature.name}>
                                        <dt className="font-medium text-feat-darkgrey-900">
                                            {feature.name}
                                        </dt>
                                        <dd className="mt-2 text-feat-darkgray">
                                            {feature.description}
                                        </dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
